import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {
    AppBar,
    Avatar,
    Badge,
    Box,
    Button, Divider,
    Drawer,
    Menu,
    MenuItem,
    Select,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import {
    ExpandMore,
    KeyboardArrowDown,
    RoomServiceOutlined,
    ShoppingBagOutlined,
    ShoppingCart
} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/Servall_Logo.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTranslation} from "react-i18next";
import {navbarSelect} from "../css/navbarStyles";
import {grey} from "@mui/material/colors";
import CircleIcon from "@mui/icons-material/Circle";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import LocationModal from "../components/navbar/locationModal";
import SnackBar from "../components/snackbar";
import {getToken} from "../services/AuthService";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {setUser} from "../features/userSlice";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {setOpenCartDrawer} from "../features/variablesSlice";

const navigationButtonStyle = {
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'start',
    columnGap: '0.5rem',
    fontSize: '0.80rem',
    fontWeight: '600',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '0.25rem',
    padding: '0.25rem 0.5rem',
    transition: '0.35s'
}

const Navbar = () => {
    const dispatch = useDispatch();
    const path = useLocation();
    const [t, i18n] = useTranslation("global");
    const [openLocationModal, setOpenLocationModal] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState({
        open: false,
        message: '',
        severity: 'success',
    });
    const user = useSelector((state) => state.user.user);
    const token = getToken();
    const [cartLength, setCartLength] = useState(0);
    // const [currency, setCurrency] = useState('USD')
    const [language, setLanguage] = useState('English')
    const [openMyDrawer, setOpenMyDrawer] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const location = useLocation();
    const restaurantData = useSelector(state => state.restaurantData.specificRestaurantData);

    const {cart} = useSelector((state) => state.allCart);

    useEffect(() => {
        setCartLength(cart.length)
    }, [cart]);

    const navigate = useNavigate()

    // const currencyData = [
    //     {
    //         name: "United States Dollar ($)",
    //         value: "USD",
    //     },
    //     {
    //         name: 'Brazilian Real (R$)',
    //         value: 'BRL',
    //     },
    //     {
    //         name: 'Euro (€)',
    //         value: 'EUR',
    //     },
    //     {
    //         name: 'Indian Rupee (₹)',
    //         value: 'INR',
    //     },
    //     {
    //         name: 'Japanese Yen (¥)',
    //         value: 'JPY',
    //     },
    //     {
    //         name: 'South Korean Won (₩)',
    //         value: 'KRW',
    //     },
    //     {
    //         name: 'Philippine Peso (₱)',
    //         value: 'PHP',
    //     },
    //     {
    //         name: 'Singapore Dollar ($)',
    //         value: 'SGD',
    //     }
    // ]

    const languages = [
        {
            name: 'English',
        },
        {
            name: "Japanese",
        },
        {
            name: 'Portuguese',
        }
    ]

    // const handleCloseSnackbar = () => {
    //     setSnackbarOptions({...snackbarOptions, open: false})
    // };

    const theme = useTheme();
    const isSmallV = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        const lang2 = localStorage.getItem("languageChoosen");
        i18n.changeLanguage(lang2);

        if (getToken() && user && user.loggedInUserInformation && user.loggedInUserInformation.id) {
            setIsUserLoggedIn(true);
        }
    }, [user]);


    // const handleCurrencyChange = (e) => {
    //     setCurrency(e.target.value)
    // }

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleLogout = () => {
        localStorage.removeItem('servallAuthUserToken');
        dispatch(setUser({
            user_type: 'guest',
            phoneNumber: '',
            order_type: 'non',
            location: {
                lat: 51.509865,
                long: -0.118092,
                streetName: '',
                streetNumber: '',
                house: '',
                city: '',
                country: '',
                address: 'Select an Address'
            },
            deliveryOptions: {},
            orderDateTime: {
                schedule: 'now',
                date: '',
                timeSlot: '',
                start_time: '',
                end_time: '',
                formattedDate: ''
            },
            firstName: '',
            lastName: '',
            email: '',
            isDeliverable: 'not_defined',
            loggedInUserInformation: {}
        }));
        setIsUserLoggedIn(false);
        navigate("/")
    }

    const handleDashboardNavigation = (route) => {
        if (user && user.loggedInUserInformation && user.loggedInUserInformation.id && getToken()) {
            navigate(route)
            setOpenMyDrawer(false)
        } else {
            setSnackbarOptions({
                open: true,
                message: 'Please Login First',
                severity: 'error',
            })
        }
    }

    return (
        <>
            <AppBar position="static" sx={{backgroundColor: "white", boxShadow: 'none', height: 'fit-content'}}>
                <Toolbar sx={{
                    minHeight: '0px',
                    '&.MuiToolbar-root': {minHeight: '0px', padding: '0 0', flexDirection: 'column'}
                }}>
                    <Stack direction='row' sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '97.5vw',
                        marginX: 'auto',
                        height: '52px'
                    }}>
                        {isSmallV && (
                            <Stack sx={{paddingLeft: '0.5rem'}}>
                                <Box component={'img'} src={logo} alt={'servall'}
                                     sx={{cursor: 'pointer', height: '40px', width: '70px', margin: '0 0'}}
                                     onClick={() => navigate("/")}/>
                            </Stack>
                        )}
                        {!isSmallV && (
                            <Stack direction='row' sx={{
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                columnGap: '1rem',
                                padding: '0.25rem 0'
                            }}>
                                <Box component={'img'} src={logo} alt={'servall'}
                                     sx={{cursor: 'pointer', height: '40px', width: '70px', margin: '0 0'}}
                                     onClick={() => navigate("/")}/>
                                {location.pathname.startsWith("/restaurants") && (restaurantData && (restaurantData.takeaway || restaurantData.delivery)) && (
                                    <Stack onClick={() => setOpenLocationModal(true)} direction='row' sx={{
                                        cursor: 'pointer',
                                        padding: '0.45rem 1.25rem',
                                        backgroundColor: grey[300],
                                        borderRadius: '25px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        columnGap: '0.75rem'
                                    }}>
                                        <Typography variant='body2' sx={{
                                            width: '100px',
                                            maxWidth: '100px',
                                            overflow: 'hidden',
                                            color: 'black',
                                            fontSize: '0.825rem',
                                            fontWeight: '600',
                                            textOverflow: 'ellipsis',
                                            textWrap: 'nowrap'
                                        }}>{user.location.address} </Typography>
                                        <CircleIcon sx={{height: '5px', width: '5px', color: 'black'}}/>
                                        <Typography sx={{
                                            width: '100px',
                                            color: 'black',
                                            fontSize: '0.85rem',
                                            fontWeight: '600',
                                            textOverflow: 'ellipsis',
                                            textWrap: 'nowrap',
                                            overflow: 'hidden'
                                        }}>{user.orderDateTime.schedule === 'now' ? `Now ${user.orderDateTime.formattedDate} ${user.orderDateTime.start_time} - ${user.orderDateTime.end_time}` : (user.orderDateTime.formattedDate + ', ' + user.orderDateTime.start_time + ' - ' + user.orderDateTime.formattedDate + ', ' + user.orderDateTime.end_time)}</Typography>
                                    </Stack>
                                )}
                            </Stack>
                        )}
                        {isSmallV && (<>
                            <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.5rem'}}>
                                <Stack direction='row' sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    columnGap: '0.25rem',
                                    padding: '0 0.25rem'
                                }}>
                                    <IconButton onClick={()=> dispatch(setOpenCartDrawer(true))}>
                                        <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                               sx={{fontSize: '8px'}} color="secondary" badgeContent={cartLength}>
                                            <ShoppingCart sx={{height: '25px', width: '25px', color: 'black'}}/>
                                        </Badge>
                                    </IconButton>
                                </Stack>
                                <IconButton onClick={() => setOpenMyDrawer(true)}>
                                    <MenuIcon sx={{color: 'black'}}/>
                                </IconButton>
                            </Stack>

                            <Drawer open={openMyDrawer} onClose={() => setOpenMyDrawer(false)} anchor='left'>
                                <Stack sx={{alignItems: 'space-between', height: '100%', justifyContent: 'space-between'}}>
                                    <Stack rowGap='1rem' sx={{backgroundColor: 'white', padding: '1rem', width: {xs: '85vw', sm: '350px'}, maxWidth: '350px'}}>
                                        <Stack direction='row' sx={{padding: '0 0.5rem',alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Box component='img' src={logo} alt={'logo'} sx={{height: '50px', width: '100px'}}/>
                                            <IconButton size={'small'} onClick={() => setOpenMyDrawer(false)} sx={{width: 'fit-content', marginLeft: 'auto'}}>
                                                <CloseIcon fontSize={'small'} />
                                            </IconButton>
                                        </Stack>
                                        {(token && user && user.loggedInUserInformation && user.loggedInUserInformation.id && user.user_type === 'logged_in') ?
                                            <Stack sx={{rowGap: '0.35rem'}}>
                                                <Stack onClick={()=> handleDashboardNavigation("/dashboard")} direction='row' sx={navigationButtonStyle}>
                                                    <PersonIcon sx={{height: '1.25rem', width: '1.25rem'}}/>
                                                    Manage my account
                                                </Stack>
                                                <Divider />
                                                <Stack onClick={()=> handleDashboardNavigation("/dashboard/orders")} direction='row' sx={navigationButtonStyle}>
                                                    <ShoppingBagOutlined sx={{height: '1.25rem', width: '1.25rem'}}/>
                                                    Orders
                                                </Stack>
                                                <Divider />
                                                <Stack onClick={()=> handleDashboardNavigation("/dashboard/reservations")} direction='row' sx={navigationButtonStyle}>
                                                    <RoomServiceOutlined sx={{height: '1.25rem', width: '1.25rem'}}/>
                                                    Reservations
                                                </Stack>
                                                <Divider />
                                                <Stack onClick={()=> handleLogout()} direction='row' sx={navigationButtonStyle}>
                                                    <LogoutIcon sx={{height: '1.25rem', width: '1.25rem'}}/>
                                                    Logout
                                                </Stack>
                                            </Stack> :
                                            <>
                                                <Button sx={{backgroundColor: 'black', borderRadius: '0px', color: 'white'}} onClick={() => navigate("/account/signin", {state: {goBack: path.pathname}})}>Sign In</Button>
                                                <Stack sx={{padding: '0.5rem 0.5rem 0.5rem 0.5rem'}}>
                                                    {/*<Typography variant='body2' sx={{fontSize: '13px'}}>*/}
                                                    {/*    Add your restaurant*/}
                                                    {/*</Typography>*/}
                                                    <Typography variant='body2' sx={{fontSize: '13px'}}>
                                                        Sign up to deliver
                                                    </Typography>
                                                </Stack>
                                            </>
                                        }

                                        <Box sx={{height: '1px', width: '100%', backgroundColor: grey[300]}}/>

                                        <Box>
                                            <Select
                                                id="language"
                                                value={language}
                                                variant='outlined'
                                                onChange={(e) => handleLanguageChange(e)}
                                                sx={navbarSelect}
                                            >
                                                {
                                                    languages.map((lang, index) => (
                                                        <MenuItem key={index} value={lang.name}>
                                                            {lang.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </Box>
                                    </Stack>
                                    <Stack sx={{padding: '1rem', rowGap: '1rem'}}>
                                        <Stack direction={{xs: 'column', sm: 'row'}} sx={{alignItems: {xs: 'start', sm: 'center'}, justifyContent: 'space-between'}}>
                                            <Typography variant='body2'>Best restaurants in your pocket</Typography>
                                        </Stack>
                                        <Button sx={{
                                            backgroundColor: grey[300],
                                            borderRadius: '25px',
                                            color: 'black',
                                            width: '150px',
                                            fontSize: '14px',
                                            fontWeight: '500'
                                        }}>Get the App</Button>
                                    </Stack>
                                </Stack>
                            </Drawer>
                        </>)}

                        {!isSmallV && (
                            <Stack direction='row' sx={{display: 'flex', alignItems: 'center'}}>
                                {/*<Box>
                                    <Select
                                        id="currency"
                                        value={currency}
                                        variant='outlined'
                                        onChange={(e)=> handleCurrencyChange(e)}
                                        renderValue={(value)=> value}
                                        sx={navbarSelect}
                                    >
                                        {
                                            currencyData.map((currency, index) => (
                                                <MenuItem key={index} value={currency.value}>
                                                    {currency.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Box>*/}
                                <Box>
                                    <Select
                                        value={language}
                                        variant='outlined'
                                        onChange={(e) => handleLanguageChange(e)}
                                        sx={navbarSelect}
                                    >
                                        {
                                            languages.map((lang, index) => (
                                                <MenuItem key={index} value={lang.name}>
                                                    {lang.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Box>
                                <Stack direction='row' sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    columnGap: '1rem',
                                    padding: '0 1rem'
                                }}>
                                    <Typography variant='body2' sx={{
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        color: 'black'
                                    }}>Cart</Typography>
                                    <Badge anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                           sx={{fontSize: '8px'}} color="secondary" badgeContent={cartLength}>
                                        <ShoppingCart sx={{height: '25px', width: '25px', color: 'black'}}/>
                                    </Badge>
                                </Stack>
                                <Box sx={{width: '1px', height: '30px', backgroundColor: grey[400]}}/>
                                <Box sx={{marginLeft: '1rem'}}>
                                    {isUserLoggedIn ?
                                        <>
                                            <Stack onClick={handleOpenUserMenu} direction='row' sx={{columnGap: '0.5rem', alignItems: 'center', cursor: 'pointer'}}>
                                                <Avatar sx={{height: '30px', width: '30px'}}>
                                                    <AccountCircleIcon sx={{height: '100%', width: '100%'}}/>
                                                </Avatar>
                                                <ExpandMore sx={{height: '1rem', width: '1rem', color: 'black'}}/>
                                            </Stack>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                onClick={handleClose}
                                                slotProps={{
                                                    paper: {
                                                        elevation: 0,
                                                        sx: {
                                                            overflow: 'visible',
                                                            filter: 'drop-shadow(0px 2px 3px rgba(0,0,0,0.32))',
                                                            mt: 0.5,
                                                            p: '0.5rem 0',
                                                            '&::before': {
                                                                content: '""',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 14,
                                                                width: 10,
                                                                height: 10,
                                                                backgroundColor: 'background.paper',
                                                                transform: 'translateY(-50%) rotate(45deg)',
                                                                zIndex: 0,
                                                            },
                                                        },
                                                    }
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <MenuItem sx={{columnGap: '0.5rem', fontSize: '0.8rem', fontWeight: '600'}} onClick={()=> handleDashboardNavigation()}>
                                                    <PersonIcon />
                                                    Manage my account
                                                </MenuItem>
                                                <MenuItem sx={{columnGap: '0.5rem', fontSize: '0.8rem', fontWeight: '600'}} onClick={()=> handleLogout()}>
                                                    <LogoutIcon />
                                                    Logout
                                                </MenuItem>
                                            </Menu>
                                        </>
                                        :
                                        <Typography variant='body2' sx={{
                                            fontSize: '14px',
                                            fontWeight: '800',
                                            color: 'black',
                                            cursor: 'pointer'
                                        }}
                                                    onClick={() => navigate("/account/signin", {state: {goBack: path.pathname}})}>
                                            Sign in
                                        </Typography>}
                                </Box>
                            </Stack>
                        )}
                    </Stack>
                    <Box sx={{width: '100%', height: '1px', backgroundColor: grey[300]}}/>
                    {
                        (isSmallV && location.pathname.startsWith("/restaurant")) && (restaurantData && (restaurantData.takeaway || restaurantData.delivery)) &&
                        <Stack onClick={() => setOpenLocationModal(true)} direction='row'
                               sx={{width: '100%', alignItems: 'start', padding: {xs: '0', md: '0 1rem'}}}>
                            <Stack>
                                <Typography variant='body2' sx={{
                                    fontSize: '0.8rem',
                                    color: grey[800],
                                    alignSelf: 'start'
                                }}>{user.orderDateTime.schedule === 'now' ? 'Delivery Now' : user.orderDateTime.formattedDate + ', ' + user.orderDateTime.start_time + ' - ' + user.orderDateTime.formattedDate + ', ' + user.orderDateTime.end_time}</Typography>
                                <Stack direction='row'
                                       sx={{cursor: 'pointer', alignItems: 'center', columnGap: '0.5rem'}}>
                                    <Typography variant='body2' sx={{
                                        fontSize: '0.9rem',
                                        color: 'black',
                                        fontWeight: '600'
                                    }}> {user.location.address} </Typography>
                                    <KeyboardArrowDown sx={{height: '20px', width: '20px', color: 'black'}}/>
                                </Stack>
                            </Stack>
                        </Stack>
                    }
                </Toolbar>
            </AppBar>

            <SnackBar setSnackbarParam={setSnackbarOptions} snackbarParams={snackbarOptions}/>
            {openLocationModal &&
                <LocationModal openLocationModal={openLocationModal} setOpenLocationModal={setOpenLocationModal}/>}
        </>
    );
};

export default Navbar;
